body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  background-color: #000;
  color: #eee;
  
  
}

h1 {
  color: #f5ce4f;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.App {
  max-width: 980px;
  margin: 0 auto;
  min-height: 100vh;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column; }
  .App h1 {
    margin: 0; }

.HomePage {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-flex-grow: 1;
          flex-grow: 1; }
  .HomePage .DaysContainer {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-grow: 1;
            flex-grow: 1; }
    .HomePage .DaysContainer .ScrollContainer {
      width: 100%;
      box-sizing: border-box;
      background: #eae9c852;
      overflow: hidden; }
      .HomePage .DaysContainer .ScrollContainer .Items {
        position: relative; }
        .HomePage .DaysContainer .ScrollContainer .Items .Item {
          box-sizing: border-box;
          position: absolute;
          left: 0;
          border: 1px solid #2d1e03;
          border-radius: 2px;
          width: 100%;
          -webkit-transition: top 80ms, opacity 200ms;
          transition: top 80ms, opacity 200ms; }
          .HomePage .DaysContainer .ScrollContainer .Items .Item .debuginfo {
            position: absolute;
            top: 2px;
            right: 5px;
            color: #fff;
            font-size: 8px; }
    .HomePage .DaysContainer .DayItem {
      font-family: monospace;
      display: -webkit-flex;
      display: flex;
      min-width: 100%;
      box-sizing: border-box;
      margin: 3px; }
      .HomePage .DaysContainer .DayItem .EditorContainer {
        border: 1px solid #2d1e03;
        border-radius: 2px;
        -webkit-flex-grow: 1;
                flex-grow: 1;
        margin-left: 3px; }
      .HomePage .DaysContainer .DayItem.today {
        font-weight: bold; }
        .HomePage .DaysContainer .DayItem.today a:link, .HomePage .DaysContainer .DayItem.today a:visited, .HomePage .DaysContainer .DayItem.today a:active {
          color: #b9d4ff; }
      .HomePage .DaysContainer .DayItem.Sun a:link, .HomePage .DaysContainer .DayItem.Sun a:visited, .HomePage .DaysContainer .DayItem.Sun a:active {
        color: #d89a9a; }
      .HomePage .DaysContainer .DayItem a {
        text-decoration: none; }
        .HomePage .DaysContainer .DayItem a:link, .HomePage .DaysContainer .DayItem a:visited, .HomePage .DaysContainer .DayItem a:active {
          color: #6ea0f1; }
        .HomePage .DaysContainer .DayItem a:hover {
          opacity: 0.82; }


.Header {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: center;
          align-items: center; }

.Footer {
  padding: 3px 0 4px 0;
  text-align: center;
  font-size: 8px;
  color: #6f6f6f;
  font-weight: 300;
  text-transform: uppercase;
  letter-spacing: 3.3px; }

