.HomePage {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    // min-height: 100vh;

    
    .DaysContainer {
        display: flex;
        flex-grow: 1;
        // display: grid;
        // grid-template-columns: 1fr 1fr;

        .ScrollContainer {
            // height: 100%;
            width: 100%;
            box-sizing: border-box;
            background: #eae9c852;
            overflow: hidden;


            .Items {
                position: relative;
                .Item {
                    box-sizing: border-box;
                    position: absolute;
                    left: 0;
                    border: 1px solid #2d1e03;
                    border-radius: 2px;
                    width: 100%;
                    transition: top 80ms, opacity 200ms;

                    .debuginfo {
                        position: absolute;
                        top: 2px;
                        right: 5px;
                        color: #fff;
                        font-size: 8px;
                    }
                    
                }
            }
        
            
        }
        
        .DayItem {
            font-family: monospace;
    
            display: flex;
            // border: 1px solid #353500;
            min-width: 100%;
            box-sizing: border-box;
            margin: 3px;
    
            .EditorContainer {
                border: 1px solid #2d1e03;
                border-radius: 2px;
                flex-grow: 1;
                margin-left: 3px;
            }
    
            &.today {
                font-weight: bold;
                a {
                    &:link,&:visited, &:active {
                        color: #b9d4ff;
                    }
                }
            }
            
            &.Sun {
                a {
                    &:link,&:visited, &:active {
                        color: #d89a9a;
                    }
                }
            }
            a {
                text-decoration: none;
    
                &:link,&:visited, &:active {
                    color: #6ea0f1;
                }
                &:hover {
                    opacity: 0.82;
                }
            }
        }
    }
}