.App {
    max-width: 980px;
    margin: 0 auto;

    min-height: 100vh;
    display: flex;
    flex-direction: column;


    h1 {
        margin: 0;
    }
}
